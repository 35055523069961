import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './App.css';

function App() {
  const [valores, setValores] = useState([]);
  const [valoresTarjeta, setValoresTarjeta] = useState([]);

  useEffect(() => {
    fetchValores();
    fetchValoresTarjeta();
  }, []);

  async function fetchValores() {
    const apiEndpoint = 'https://dolarapi.com/v1/dolares';
    try {
      const response = await fetch(apiEndpoint);
      const data = await response.json();
      setValores(data);

      const fechaActualizacionDiv = document.getElementById('fechaActualizacionDiv');
      fechaActualizacionDiv.textContent = `${formatearFecha(data[0].fechaActualizacion) } hs.`;

    } catch (error) {
      console.error('Error al obtener los valores de dólar:', error);
    }
  }

  function formatearFecha(fechaString) {
    const fechaUtc = new Date(fechaString);
    const fechaLocal = fechaUtc.toLocaleString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return fechaLocal;
  }

  async function fetchValoresTarjeta() {
    const apiEndpoint = 'https://dolarapi.com/v1/dolares/tarjeta';
    try {
      const response = await fetch(apiEndpoint);
      const data = await response.json();
      setValoresTarjeta(data);
    } catch (error) {
      console.error('Error al obtener los valores de dólar:', error);
    }
  }

  function obtenerCompraPorCasa(casaBuscada) {
    const entradaCasa = valores.find(entrada => entrada.casa === casaBuscada);
    return entradaCasa ? entradaCasa.compra.toFixed(2) : null;
  }
  function obtenerVentaPorCasa(casaBuscada) {
    const entradaCasa = valores.find(entrada => entrada.casa === casaBuscada);
    return entradaCasa ? entradaCasa.venta.toFixed(2) : null;
  }
  function obtenerCompraPorCasaTarjeta(casaBuscada) {
    return valoresTarjeta.compra ? valoresTarjeta.compra.toFixed(2) : null;
  }
  function obtenerVentaPorCasaTarjeta(casaBuscada) {
    return valoresTarjeta.venta ? valoresTarjeta.venta.toFixed(2) : null;
  }
  return (
    <Container className="App mb-4">
        <Row>
          <Col className="col-12 text-center pt-5 pb-2">
          <h1 ><span className="flag-icon flag-icon-ar"></span> Dólar actual en Argentina</h1>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 text-center pb-3">
            <label>Fecha actualización: </label>
            <div id="fechaActualizacionDiv"></div>
          </Col>
        </Row>
      <Row className='pt-3'>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar Oficial</Card.Title>
              <Row >
              <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasa('oficial')}</div>
                  <small>Compra</small>
              </div>
              <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasa('oficial')}</div>
                  <small>Venta</small>
              </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar Libre</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasa('blue')}</div>
                  <small>Compra</small>
                </div>
                <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasa('blue')}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar MEP</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasa('bolsa')}</div>
                  <small>Compra</small>
                </div>
                <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasa('bolsa')}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar Turista/Ahorro</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasaTarjeta('tarjeta')}</div>
                  <small>Compra</small>
                </div>
                <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasaTarjeta('tarjeta')}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar Mayorista</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasa('mayorista')}</div>
                  <small>Compra</small>
                </div>
                <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasa('mayorista')}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar CCL</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='rojo'>${obtenerCompraPorCasa('contadoconliqui')}</div>
                  <small>Compra</small>
                </div>
                <div className='col-6'>
                  <div className='verde'>${obtenerVentaPorCasa('contadoconliqui')}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-sm-4 mb-4">
          <Card>
            <Card.Body>
              <Card.Title className="pb-1">Dólar Promedio</Card.Title>
              <Row >
                <div className='col-6'>
                  <div className='verde'>${(parseFloat(obtenerCompraPorCasa('blue')) + parseFloat(obtenerVentaPorCasa('blue')))/2}</div>
                  <small>Venta</small>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
